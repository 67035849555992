<template>
  <div>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component class="mt-5" :is="item.component" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosAuxilioFuneral from './DadosAuxilioFuneral.vue'
import DadosBancariosAuxilioFuneral from './DadosBancariosAuxilioFuneral.vue'
import DocumentosAssociadoAuxilioFuneral from './DocumentosAssociadoAuxilioFuneral.vue'
import DocumentosProcessoAuxilioFuneral from './DocumentosProcessoAuxilioFuneral.vue'

export default {
  components: {
    DadosAuxilioFuneral,
    DadosBancariosAuxilioFuneral,
    DocumentosAssociadoAuxilioFuneral,
    DocumentosProcessoAuxilioFuneral
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-dados-auxilio',
          component: 'DadosAuxilioFuneral'
        },
        {
          tab: 'Dados Bancários',
          content: 'tab-dados-bancarios',
          component: 'DadosBancariosAuxilioFuneral'
        },
        {
          tab: 'Documentos do Associado',
          content: 'tab-documentos-associado',
          component: 'DocumentosAssociadoAuxilioFuneral'
        },
        {
          tab: 'Documentos do Processo',
          content: 'tab-documentos-processo',
          component: 'DocumentosProcessoAuxilioFuneral'
        },
      ]
    }
  }
}
</script>
