<template>
  <div>
    <div class="main">
      <v-row>
        <v-col offset-sm="12" md="12">
          <TitleH2 class="mt-3 mb-0 mx-3" title="Dados Pessoais"/>
          <p class="mx-3 mb-5">Requerimento do Auxílio Funeral</p>
          <FormField :fields="fields" :validate="validacao"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center mt-5" md="12" order-md="1">
          <div class="footer-pagina">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn class="ml-3" to="/auxilio-funeral">
                <v-icon small>mdi-arrow-left-thin </v-icon>
                Voltar
              </v-btn>
            </v-toolbar>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import auxilioService from '@/services/AuxilioService'
import utilService from '@/services/UtilService'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from "@/components/atoms/StatusChip";
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'


export default {
  components: {
    TitleH2,
    FormField,
    StatusChip,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      criandoFormulario: false,
      validacao: 0,
      auxilio: {},
      fields: {
        codigoBanco: {
          label: 'Banco',
          valueField: null,
          value: '',
          cols: 6,
          field: 'SelectField',
          items: [],
          keyValue: 'COMPE',
          keyName: 'termoPesquisa',
          readonly: true,
          clearable: false,
          rules: [],
        },
        tipoConta: {
          label: 'Tipo Conta',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'enum',
          keyName: 'label',
          readonly: true,
          clearable: false,
          rules: [],
        },
        nomeTitular: {
          label: 'Titular',
          valueField: null,
          cols: 6,
          value: '',
          readonly: true,
          field: 'InputField',
        },
        cpfCnpj: {
          label: 'CPF',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputField',
          readonly: true,
          rules: []
        },
        agencia: {
          label: 'Agência',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          readonly: true,
          rules: []
        },
        conta: {
          label: 'Conta',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          readonly: true,
          rules: []
        },
        digito: {
          label: 'Dígito',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          readonly: true,
          rules: []
        },
      },
    }
  },

  created() {
    this.instituicoesFinanceiras();
    this.tiposConta();
    this.dadosBancarios();
  },

  methods: {
    dadosBancarios() {
      auxilioService
        .dadosBancariosPorAuxilio(this.$route.params.id)
        .then(({ data }) => {
          this.criarFormulario(data);
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        });
    },

    criarFormulario(data) {
      this.criandoFormulario = true;
      Object.entries(data).map(([key, value]) => {
        if(this.fields[key]) {
          this.fields[key].valueField = value;
        }
      })
    },

    instituicoesFinanceiras() {
      utilService.instituicoesFinanceiras().then(({ data }) => {
        this.fields.codigoBanco.items = data.map(item => ({
          ...item,
          termoPesquisa: `${item.COMPE} | ${item.ShortName}`,
        }))
      })
    },

    tiposConta() {
      utilService.tiposContaBancaria().then(({ data }) => {
        this.fields.tipoConta.items = data.map(item => ({
          enum: item,
          label: item.replace('_', ' '),
        }))
      })
    },
  }
}
</script>

<style scoped>
  .footer-pagina { margin-top: 2rem;}
</style>
