<template>
  <div>
    <DialogConfirmation
      tag="delete"
      title="Remover Documento"
      :show="exibirDialogAviso"
      :loading="removendo"
      @confirm="removerDocumento()"
      @close="
        exibirDialogAviso = false
        documentoSelecionado = null
      "
    >
      <template v-slot:body>
        <div class="mt-5">
          <h3>
            Ao confirmar, este documento será removido. Deseja continuar?
          </h3>
        </div>
      </template>
    </DialogConfirmation>

    <DialogConfirmation
      tag="add"
      title="Adicionar Documento"
      :scrollable="true"
      :width="650"
      :show="exibirDialogAdicionarDocumento"
      :key="keyDialogAdicionarDocumento"
      :loading="cadastrando"
      @close="fecharDialogAdicionarDocumento()"
      @confirm="adicionarDocumento()"
    >
      <template v-slot:body>
        <v-form class="form-fields fields">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados do Documento" />
          <FormField
            :validate="keyFormValidacao"
            :fields="fields"
            @changeFile="onChangeDocumento($event)"
          />
        </v-form>
      </template>
    </DialogConfirmation>

    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="listaDocumentos"
      :loading="carregandoAcao"
      :itemsPerPage="listaDocumentos.length"
    >

      <template
        v-slot:top
      >
        <v-toolbar flat>
          <ButtonMain
            :primary="true"
            text="Adicionar Documento"
            @click.native="abrirDialogAdicionarDocumento()"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.tipo`]="{ item }">
        <span>{{ identificarTipoArquivo(item.caminho) }}</span>
      </template>


      <template v-slot:[`item.acoes`]="{ item }">
        <v-row>
          <TooltipMain top text="Ver Documento">
            <ButtonIcon
              class="mx-1"
              :primary="true"
              icon="eye"
              @click.native="verDocumento(item.caminho)"
            />
          </TooltipMain>

          <TooltipMain top text="Remover Documento">
            <ButtonIcon
              class="mx-1"
              :secondary="true"
              icon="trash-can-outline"
              @click.native.stop="
                exibirDialogAviso = true
                documentoSelecionado = item
              "
            />
          </TooltipMain>
        </v-row>
      </template>
    </CrudTable>

    <v-row>
      <v-col class="text-center mt-5" md="12" order-md="1">
        <div class="footer-pagina">
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn class="ml-3" to="/auxilio-funeral">
              <v-icon small>mdi-arrow-left-thin </v-icon>
              Voltar
            </v-btn>
          </v-toolbar>
        </div>
      </v-col>
    </v-row>
  </div>

</template>

<script>
  import CrudTable from '@/components/molecules/tables/CrudTable'
  import ButtonIcon from '@/components/atoms/buttons/ButtonIcon'
  import ButtonMain from '@/components/atoms/buttons/ButtonMain'
  import TooltipMain from '@/components/atoms/TooltipMain'
  import TitleH2 from '@/components/atoms/title/TitleH2.vue'
  import FormField from '@/components/atoms/forms/FormField.vue'
  import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
  import globalMethods from '@/mixins/globalMethods'

  import utilService from '@/services/UtilService'
  import auxilioService from '@/services/AuxilioService'

  export default {
    components: {
      CrudTable,
      ButtonIcon,
      TooltipMain,
      ButtonMain,
      TitleH2,
      FormField,
      DialogConfirmation,
    },

    mixins: [ globalMethods ],

    data() {
      return {
        keyDialogAdicionarDocumento: 0,
        keyFormValidacao: 0,
        exibirDialogAdicionarDocumento: false,
        exibirDialogAviso: false,
        carregandoAcao: false,
        cadastrando: false,
        removendo: false,
        listaDocumentos: [],
        headers: [
          { text: 'Descrição', value: 'descricao', sortable: false },
          { text: 'Tipo', value: 'tipo', sortable: false },
          { text: 'Ações', value: 'acoes', width: 150, sortable: false }
        ],
        validadores: {
          obrigatorio: 'Campo obrigatório',
        },
        fields: {
          tipoDocumento: {
            label: 'Tipo do Documento',
            textoNadaEncontrado: 'Todos os tipos de documento já cadastrados',
            valueField: null,
            value: '',
            cols: 12,
            field: 'SelectField',
            items: [],
            keyValue: 'id',
            keyName: 'descricao',
            clearable: false,
            rules: [(value) => !!value || this.validadores.obrigatorio],
          },
          documento: {
            icon: 'mdi-file',
            accept: '.jpg, .jpeg, .pdf',
            label: 'Inserir Documento',
            valueField: null,
            value: '',
            cols: 12,
            key: 4,
            field: 'FileInput',
            rules: [(value) => !!value || this.validadores.obrigatorio],
          },
      },
    }
    },

    created() {
      this.documentosProcesso();
    },

    methods: {
      documentosProcesso() {
        this.carregandoAcao = true;
        const filtro = { caama: 'true' }

        auxilioService
          .documentosPorAuxilio(this.$route.params.id, filtro)
          .then(({ data }) => {
            this.listaDocumentos = data;
          })
          .catch(() => {})
          .then(() => this.carregandoAcao = false);
      },

      verDocumento(url) {
        window.open(url, '_blank');
      },

      removerDocumento() {
        this.removendo = true
        utilService
          .removerArquivoAssociado(this.documentoSelecionado.id)
          .then(({ data }) => {
            this.removendo = false
            this.exibirDialogAviso = false
            this.documentosProcesso();
          })
          .catch((err) => {
            this.removendo = false
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          });
      },

      identificarTipoArquivo(arquivo) {
        const pesquisa = arquivo.match(/(jpg|jpeg|pdf)/gi);
        const extensao = pesquisa ? pesquisa.slice(-1)[0] : null;

        if(extensao && extensao === 'pdf') {
          return 'PDF';
        }

        if(extensao && (extensao === 'jpg' || extensao === 'jpeg')) {
          return 'IMAGEM';
        }

        return '';
      },

      receberTiposDocumentos() {
        const filtro = { caama: 'true' }

        utilService
          .tiposDocumentosAuxilio(2, filtro)
          .then(({ data }) => {
            this.fields.tipoDocumento.items = data;
          });
      },

      abrirDialogAdicionarDocumento() {
        this.keyDialogAdicionarDocumento++;
        this.exibirDialogAdicionarDocumento = true;
        this.receberTiposDocumentos();
      },

      fecharDialogAdicionarDocumento() {
        this.keyDialogAdicionarDocumento++;
        this.exibirDialogAdicionarDocumento = false;

        this.fields.tipoDocumento.value = null;
        this.fields.documento.value = null;
      },

      onChangeDocumento(evento) {
        this.fields.documento.value = evento.arquivo
        this.fields.documento.valueField = evento.arquivo
      },

      adicionarDocumento() {
        if (this.verificarSeHaCampoObrigatorioNulo(this.fields, this.validadores)) {
          ++this.keyFormValidacao;
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Dados Incompletos',
          });

          return;
        }

        const arquivo = this.fields.documento.value;
        const body =  [{
          arquivo: arquivo.split(';base64,')[1],
          descricaoTipo: this.fields.tipoDocumento.items.find(item => item.id === this.fields.tipoDocumento.value).descricao,
          extensao: arquivo.split(';')[0].split('/')[1],
          idTipo: this.fields.tipoDocumento.value,
        }];

        this.cadastrando = true

        auxilioService
          .cadastrarDocumentos(Number(this.$route.params.id), body)
          .then(() => {
            this.cadastrando = false
            this.$root.vtoast.show({
              status: 'success',
              title: 'Cadastrado',
              text: 'Documento cadastrado',
            });

            this.fecharDialogAdicionarDocumento();
            this.documentosProcesso();
          })
          .catch((err) => {
            this.cadastrando = false
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          });
      },
    },
  }
</script>

<style scoped>

</style>